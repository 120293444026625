import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import config from './cookieconsent-config2';
const inBrowser = typeof window !== 'undefined';

export default {
    install: (app) => {
        if (inBrowser) {
            const REQUEST_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

            const REQUEST_CONFIG = {
                method: 'HEAD',
                mode: 'no-cors',
            };

            const checkAdsBlocked = (callback) => {
                fetch(REQUEST_URL, REQUEST_CONFIG)
                    .then((response) => {
                        callback(response.redirected);
                    })
                    .catch(() => {
                        callback(true);
                    });
            };

            // Usage example:
            checkAdsBlocked((adsBlocked) => {
                if(adsBlocked){
                    CookieConsent.hide();
                }
            });
            
            CookieConsent.run(config);
            window.$CookieConsent = CookieConsent;
            app.config.globalProperties.$CookieConsent = CookieConsent;
        }
    },
};
